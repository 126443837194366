import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC } from 'react';
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import * as OrderSummary from '../../../../../shared/orders/model';
import { Currency } from '../../../../components/Currency';

interface CreateVoucherModalProps {
  readonly order: OrderSummary.Order;
  onSuccess(): void;
  onCancel(): void;
}

export const OrderCreateVoucherModal: FC<CreateVoucherModalProps> = ({
  order,
  onCancel,
  onSuccess,
}) => {
  return (
    <Modal className="modal-large" id="createVoucherModal" isOpen>
      <JSONForm
        method="post"
        onSuccess={onSuccess}
        path={`/api/orders/${order.id}/vouchers/create`}
      >
        <ModalHeader>Create voucher #{order.id}</ModalHeader>
        <ModalBody>
          <p>Create a one-time use voucher for this order.</p>
          <FormGroup>
            <Label for="displayName">
              Display Name <small>(Optional)</small>
            </Label>
            <Input id="displayName" name="displayName" />
          </FormGroup>
          <FormGroup>
            <Label for="name">Amount</Label>
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">$</div>
              </div>
              <Input id="amount" min={0.01} name="amount" required step={0.01} type="number" />
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel} type="button">
            Cancel
          </Button>{' '}
          <Button color="primary" id="createVoucherOrder" type="submit">
            Create
          </Button>
        </ModalFooter>
      </JSONForm>
    </Modal>
  );
};

interface DeleteVoucherModalProps {
  readonly order: OrderSummary.Order;
  readonly voucherId: number;
  onSuccess(): void;
  onCancel(): void;
}

export const DeteteOrderVoucherModal: FC<DeleteVoucherModalProps> = ({
  order,
  voucherId,
  onCancel,
  onSuccess,
}) => {
  const voucher = order.vouchers.find((t) => t.id === voucherId);

  if (!voucher) {
    return null;
  }

  return (
    <Modal className="modal-large" id="deleteVoucherModal" isOpen>
      <JSONForm
        method="delete"
        onSuccess={onSuccess}
        path={`/api/orders/${order.id}/vouchers/${voucher.id!}`}
      >
        <ModalHeader>Delete voucher for #{order.id}</ModalHeader>
        <ModalBody>
          <p>
            Are you sure you want to delete the voucher valued at{' '}
            <Currency value={voucher.amount} />?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel} type="button">
            Cancel
          </Button>{' '}
          <Button color="danger" id="deleteVoucherOrder" type="submit">
            Delete
          </Button>
        </ModalFooter>
      </JSONForm>
    </Modal>
  );
};
