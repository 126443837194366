import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, PropsWithChildren, ReactNode, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { MaterialIcon } from '../../../components';
import { Currency } from '../../../components/Currency';
import { UserEntry, UserSelector } from '../../../components/UserSelector';

interface BaseEntry {
  id: number;
}

export interface ColumnSettingBase<T> {
  name: Extract<keyof T, string>;
  type: 'currency' | 'enum' | 'number' | 'text' | 'textarea' | 'toggle' | 'user' | 'users';
  enumValues?: { name: string; value: string }[];
  label?: ReactNode;
  disabled?: (value?: T) => boolean;
  customRender?: (value: T) => React.ReactNode;
}

export interface ColumnSetting<T> extends ColumnSettingBase<T> {
  type: 'currency' | 'enum' | 'number' | 'text' | 'textarea' | 'toggle';
}

export interface UserColumnSetting<T> extends ColumnSettingBase<T> {
  type: 'user';
  getDefaultValue: (value: T) => UserEntry;
}

export interface UsersColumnSetting<T> extends ColumnSettingBase<T> {
  type: 'users';
  getDefaultValue: (value: T) => UserEntry[];
}

export type ColumnSettingMulti<T> = ColumnSetting<T> | UserColumnSetting<T> | UsersColumnSetting<T>;

interface DataTableProps<T> {
  readonly title: string;
  readonly data: T[];
  readonly columns: ColumnSettingMulti<T>[];
  readonly endpoint: string;
  refresh(): void;
  canDelete?(row: T): boolean;
  extraButtons?(row: T): JSX.Element;
}

type DataTableModalType = 'add' | 'delete' | 'edit';

interface DataTableModalState {
  type: DataTableModalType;
  id?: number;
}

export const DataTable = <T extends BaseEntry>(props: DataTableProps<T>): JSX.Element => {
  const [modal, setModal] = useState<DataTableModalState | null>(null);

  return (
    <Card>
      {modal?.type === 'delete' && (
        <DeleteModal
          close={() => setModal(null)}
          endpoint={props.endpoint}
          id={modal.id!}
          refresh={props.refresh}
        />
      )}
      {(modal?.type === 'edit' || modal?.type === 'add') && (
        <EditModal
          close={() => setModal(null)}
          columns={props.columns}
          data={props.data}
          endpoint={props.endpoint}
          id={modal.id}
          refresh={props.refresh}
        />
      )}
      <CardHeader>
        <Button
          className="float-right p-0"
          color="success"
          id="createItem"
          onClick={() => {
            setModal({ type: 'add' });
          }}
          title="Create"
        >
          <MaterialIcon name="add" />
        </Button>
        {props.title}
      </CardHeader>
      <CardBody>
        <table className="table">
          <thead>
            <tr>
              {props.columns.map((column) => (
                <th key={column.name}>{column.name}</th>
              ))}
              <th />
            </tr>
          </thead>
          <tbody>
            {props.data.map((row) => (
              <DataTableRow<T>
                canDelete={props.canDelete}
                columns={props.columns}
                extraButtons={props.extraButtons}
                key={row.id}
                openModal={setModal}
                row={row}
              />
            ))}
          </tbody>
        </table>
      </CardBody>
    </Card>
  );
};

function getCell<T>(row: T, cell: ColumnSettingBase<T>): React.ReactNode {
  const value = row[cell.name];
  const { type, customRender, enumValues } = cell;

  if (customRender) {
    return customRender(row);
  }

  if (type === 'currency') {
    return <Currency value={value as unknown as number} />;
  }

  if (type === 'enum') {
    const enumValue = enumValues!.find((t) => t.value === (value as unknown));
    return enumValue ? enumValue.name : (value as unknown as string);
  }

  if (type === 'toggle') {
    return (value as unknown as boolean) ? 'Yes' : 'No';
  }

  return value as unknown as string;
}

interface DataTableRowProps<T extends BaseEntry> {
  readonly row: T;
  readonly columns: ColumnSettingMulti<T>[];
  canDelete?(row: T): boolean;
  openModal(state: DataTableModalState): void;
  extraButtons?(row: T): JSX.Element;
}

const DataTableRow = <T extends BaseEntry>({
  columns,
  row,
  openModal,
  canDelete,
  extraButtons,
}: PropsWithChildren<DataTableRowProps<T>>): JSX.Element => {
  const edit = useCallback(() => openModal({ type: 'edit', id: row.id }), [openModal, row]);
  const del = useCallback(() => openModal({ type: 'delete', id: row.id }), [openModal, row]);
  const showDelete = !canDelete || canDelete(row);

  return (
    <tr className="dataRow" id={`row_${row.id}`}>
      {columns.map((column) => (
        <td key={column.name}>{getCell(row, column)}</td>
      ))}
      <td style={{ whiteSpace: 'nowrap', width: '1%' }}>
        {extraButtons?.(row)}
        <Button className="action-edit" color="primary" onClick={edit} title="Edit">
          <MaterialIcon name="edit" />
        </Button>
        {showDelete && (
          <Button className="action-delete" color="danger" onClick={del} title="Delete">
            <MaterialIcon name="delete" />
          </Button>
        )}
      </td>
    </tr>
  );
};

interface DeleteModalProps {
  readonly endpoint: string;
  readonly id: number;
  refresh(): void;
  close(): void;
}

const DeleteModal: FC<DeleteModalProps> = ({ close, refresh, endpoint, id }) => {
  return (
    <Modal isOpen toggle={close}>
      <JSONForm
        method="delete"
        onSuccess={() => {
          toast.success('Item deleted');
          close();
          refresh();
        }}
        path={`${endpoint}/${id}`}
      >
        <ModalHeader toggle={close}>Delete entry</ModalHeader>
        <ModalBody>
          <p>Are you sure you want to delete?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" id="confirmDeleteRow">
            Delete
          </Button>{' '}
          <Button color="secondary" onClick={close}>
            Cancel
          </Button>
        </ModalFooter>
      </JSONForm>
    </Modal>
  );
};

interface EditModalProps<T extends BaseEntry> {
  readonly endpoint: string;
  readonly id?: number;
  readonly data: T[];
  readonly columns: ColumnSettingMulti<T>[];
  close(): void;
  refresh(): void;
}

const EditModal = <T extends BaseEntry>({
  id,
  data,
  refresh,
  close,
  endpoint,
  columns,
}: PropsWithChildren<EditModalProps<T>>): JSX.Element => {
  const isEdit = !!id;
  const entry = isEdit ? data.find((t) => t.id === id) : undefined;

  return (
    <Modal className="modal-large" isOpen toggle={close}>
      <JSONForm
        id="editModal"
        method={isEdit ? 'patch' : 'post'}
        onSuccess={() => {
          toast.success(isEdit ? 'Item edited' : 'Item created');
          refresh();
          close();
        }}
        path={`${endpoint}${isEdit ? `/${id}` : ''}`}
      >
        <ModalHeader toggle={close}>Edit Entry</ModalHeader>
        <ModalBody>
          {columns.map((column) => (
            <FormGroup key={column.name}>
              {column.type !== 'toggle' && (
                <Label for={column.name}>{column.label ?? column.name}:</Label>
              )}
              <EditCall column={column} entry={entry} />
            </FormGroup>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" id="submitModal">
            {isEdit ? 'Save' : 'Add'}
          </Button>{' '}
          <Button color="secondary" onClick={close}>
            Cancel
          </Button>
        </ModalFooter>
      </JSONForm>
    </Modal>
  );
};

interface EditCallProps<T extends BaseEntry> {
  readonly entry?: T;
  readonly column: ColumnSettingMulti<T>;
}

const EditCall = <T extends BaseEntry>({
  entry,
  column,
}: PropsWithChildren<EditCallProps<T>>): JSX.Element => {
  const value = (entry ? entry[column.name] : null) as unknown as string;
  const { type, name, enumValues, label } = column;
  const disabled = column.disabled?.(entry);

  if (type === 'users') {
    return (
      <UsersEditCell defaultSelected={entry ? column.getDefaultValue(entry) : []} name={name} />
    );
  }

  if (type === 'user') {
    return (
      <UserEditCell
        defaultSelected={entry ? column.getDefaultValue(entry) : undefined}
        name={name}
      />
    );
  }

  if (type === 'currency' || type === 'number') {
    return <Input defaultValue={value} disabled={disabled} id={name} name={name} type="number" />;
  }

  if (type === 'enum') {
    return (
      <Input defaultValue={value} disabled={disabled} id={name} name={name} type="select">
        {enumValues!.map((option) => (
          <option key={option.name} value={option.value}>
            {option.name}
          </option>
        ))}
      </Input>
    );
  }

  if (type === 'toggle') {
    return (
      <div className="custom-control custom-checkbox">
        <Input
          className="custom-control-input"
          defaultChecked={!!value}
          disabled={disabled}
          id={name}
          name={name}
          type="checkbox"
        />
        <Label className="custom-control-label" for={name}>
          {label ?? name}
        </Label>
      </div>
    );
  }

  return (
    <Input
      defaultValue={value}
      disabled={disabled}
      id={name}
      name={disabled ? undefined : name}
      type={type === 'textarea' ? 'textarea' : undefined}
    />
  );
};

interface UserEditCellProps {
  readonly name: string;
  readonly defaultSelected?: UserEntry;
}

const UserEditCell: FC<UserEditCellProps> = ({ defaultSelected, name }) => {
  const [id, setId] = useState(defaultSelected?.id);

  return (
    <>
      <Input name={name} type="hidden" value={id} />
      <UserSelector
        defaultSelected={defaultSelected ? [defaultSelected] : []}
        id={name}
        maxItems={1}
        singleSelectionIdChanged={setId}
      />
    </>
  );
};

interface UsersEditCellProps {
  readonly name: string;
  readonly defaultSelected: UserEntry[];
}

const UsersEditCell: FC<UsersEditCellProps> = (props) => {
  const { defaultSelected, name } = props;
  const [ids, setIds] = useState(defaultSelected.map((t) => t.id));

  return (
    <>
      {ids.map((id) => (
        <Input key={`${name}_${id}`} name={`${name}[]`} type="hidden" value={id} />
      ))}
      <UserSelector
        defaultSelected={defaultSelected}
        id={name}
        maxItems={10}
        selectionIdsChanged={setIds}
      />
    </>
  );
};
