import { APIError } from '@conventioncatcorp/common-fe';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Config, ConventionSetting, OrganizationSetting } from '../../shared/config';
import { PermissionName } from '../../shared/permissions';
import { CurrentUser } from '../../shared/user/base';
import { GlobalState, Preferences } from '../services';
import { AuthErrorCodes, LogicError } from './errorHandling';
import { EventHandlerFn } from './input';
export * from './input';
export * from './time';
export * from './useFetcher';
export * from './useForm';

export function randomChance(chance: number): boolean {
  return Math.random() < chance;
}

export { classNames } from '@conventioncatcorp/common-fe/dist/classNames';

export function isResourceError(err: unknown, resource?: string): err is APIError {
  return (
    err instanceof APIError &&
    !!err.apiResponse.errors.resource &&
    (resource ? err.apiResponse.errors.resource.name === resource : true)
  );
}

export function isAuthError(err: Error, code: AuthErrorCodes): err is APIError {
  if (!(err instanceof APIError)) {
    return false;
  }

  const { authentication } = err.apiResponse.errors;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
  return !!authentication && authentication.code === code;
}

export function isAccessError(err: Error, permission?: PermissionName): err is APIError {
  if (!(err instanceof APIError)) {
    return false;
  }

  const { access } = err.apiResponse.errors;
  return !!(permission ? access?.permissions?.includes(permission) : access);
}

export function isLogicError(err: unknown, code?: LogicError): err is APIError {
  return (
    err instanceof APIError &&
    !!err.apiResponse.errors.logic &&
    (code ? err.apiResponse.errors.logic.some((le) => le.code === code) : true)
  );
}

export function fthrow(err: Error): never {
  throw err;
}

export function truncateText(str?: string, maxLen = 128): string {
  if (!str || str.length <= maxLen) {
    return str ?? '';
  }

  return `${str.slice(0, maxLen)}...`;
}

export function clone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj)) as T;
}

interface TableKVPairProps {
  readonly name: string;
  readonly displayName: string;
  readonly value: React.ReactNode | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-shadow
export const TableKVPair: FC<TableKVPairProps> = ({ name, displayName, value }) => {
  return value ? (
    <tr>
      <th scope="row">{displayName}</th>
      <td id={name}>{value}</td>
    </tr>
  ) : null;
};

export function distinct<T>(arr: T[]): T[] {
  return [...new Set(arr)];
}

export function uniqueBy<T, R>(arr: T[], predicate: (val: T) => R): T[] {
  const unique = new Set<R>();
  const out: T[] = [];
  for (const item of arr) {
    const x = predicate(item);
    if (!unique.has(x)) {
      out.push(item);
      unique.add(x);
    }
  }

  return out;
}

export function toggleElementInArray<T>(arr: T[], id: T): T[] {
  if (arr.includes(id)) {
    return arr.filter((t) => t !== id);
  }

  return [...arr, id];
}

export function setElementInArray<T>(arr: T[], id: T, set: boolean): T[] {
  const has = arr.includes(id);
  if (!set && has) {
    return arr.filter((t) => t !== id);
  }

  if (!has && set) {
    return [...arr, id];
  }

  return arr;
}

export function generateRange(from: number, to: number): number[] {
  const out = new Array<number>(Math.abs(to - from) + 1);
  for (let i = from; i <= to; i++) {
    out[i - from] = i;
  }

  return out;
}

interface DateRangeProps {
  readonly start: Date;
  readonly end: Date;
  readonly timeZone?: string;
  readonly time?: boolean;
}

// TODO: Replace with Intl.DateTimeFormat.formatRanges when more stable
export const DateRange: FC<DateRangeProps> = ({ start, end, timeZone, time }) => {
  const startDateOpts: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    hour: time ? '2-digit' : undefined,
    minute: time ? '2-digit' : undefined,
    month: 'long',
    timeZone,
  };

  const endDateOptions: Intl.DateTimeFormatOptions = {
    ...startDateOpts,
    year: 'numeric',
  };

  const startDate = start.toLocaleDateString('en-US', startDateOpts);
  const endDate = end.toLocaleDateString('en-US', endDateOptions);

  return <>{`${startDate} - ${endDate}`}</>;
};

export function mapObjectToArray<T, R>(
  obj: Record<string, T>,
  mapper: (val: T, key: string) => R,
  discardUndefined = false,
): R[] {
  const out: R[] = [];
  for (const key in obj) {
    if (!Object.prototype.hasOwnProperty.call(obj, key)) {
      continue;
    }

    if (obj[key] === undefined && discardUndefined) {
      continue;
    }

    const val = mapper(obj[key], key);
    if (val === undefined && discardUndefined) {
      continue;
    }

    out.push(val);
  }

  return out;
}

/**
 * Checks if the user has the specified permission(s).
 */
export function checkPermission(
  user: CurrentUser,
  permissions: PermissionName | PermissionName[] = [],
  matchAny?: boolean,
): boolean {
  permissions = Array.isArray(permissions) ? permissions : [permissions];
  if (matchAny) {
    return permissions.some((permission) => user.permissions.includes(permission));
  }

  return permissions.every((permission) => user.permissions.includes(permission));
}

export function randInt(min: number, max: number): number {
  return Math.floor(min + max * Math.random());
}

export function randomSample<T>(arr: T[]): T {
  return arr[randInt(0, arr.length)];
}

export async function delayPromise(t: number): Promise<void> {
  return await new Promise((res) => {
    setTimeout(res, t);
  });
}

export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function sumBy<T = number>(arr: T[], predicate: (val: T, idx: number) => number): number {
  return arr.reduce((total, val, idx) => total + predicate(val, idx), 0);
}

export function sum(arr: number[]): number {
  return arr.reduce((total, val) => total + val, 0);
}

export function ordinalSuffix(i: number): string {
  const j = i % 10;
  const k = i % 100;
  let suff = 'th';

  if (j === 1 && k !== 11) {
    suff = 'st';
  }

  if (j === 2 && k !== 12) {
    suff = 'nd';
  }

  if (j === 3 && k !== 13) {
    suff = 'rd';
  }

  return `${i}${suff}`;
}

export interface NameProps {
  firstName: string;
  lastName: string;
  preferredName?: string | null;
}

export function renderName({ firstName, lastName, preferredName }: NameProps): string {
  if (preferredName) {
    return `${preferredName} (${firstName} ${lastName})`;
  }

  return `${firstName} ${lastName}`;
}

export function omit<T extends object, K extends keyof T>(obj: T, ...fields: K[]): Omit<T, K> {
  const out: Partial<T> = {};
  for (const key in obj) {
    if (!Object.prototype.hasOwnProperty.call(obj, key) || fields.includes(key as unknown as K)) {
      continue;
    }

    out[key] = obj[key];
  }

  return out as Omit<T, K>;
}

/**
 * Tries to show the display name of an object, falls back to internal and to error message name.
 */
export function displayName(obj: { name?: string; displayName?: string | null }): string {
  return obj.displayName ?? obj.name ?? 'ERR_NO_DISPLAY_NAME';
}

export function useGlobalState<T>(cb: (s: GlobalState) => T): T {
  return useSelector(cb);
}

export function useConfig(): Config {
  return useGlobalState((state) => state.config!);
}

export function useUser(): CurrentUser | null {
  return useGlobalState((state) => state.user);
}

export function usePreferences(): Preferences | null {
  return useGlobalState((state) => state.preferences);
}

export function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

export function useOrganization(): OrganizationSetting {
  return useGlobalState((state) => state.config!.organization);
}

export function useConvention(): ConventionSetting {
  const config = useConfig();
  const host = window.location.host.toLowerCase();
  for (const c of config.conventions) {
    if (host === c.domain) {
      return c;
    }
  }

  const conv = config.conventions.find((t) => t.id === config.activeConventionId);

  if (!conv) {
    throw new Error(`Convention not found (${config.activeConventionId})`);
  }

  return conv;
}

export type Fn = () => void;

export const noop = (): void => {
  /* noop */
};

export function useConstantSetters<T>(setter: (val: T) => void, value: T): Fn {
  return useCallback(() => {
    setter(value);
  }, [value]);
}

export function useTernaryState<T>(defaultValue: T, a: T, b: T, c: T): [T, Fn, Fn, Fn] {
  const [val, setVal] = useState(defaultValue);
  const setA = useConstantSetters(setVal, a);
  const setB = useConstantSetters(setVal, b);
  const setC = useConstantSetters(setVal, c);
  return [val, setA, setB, setC];
}

export function useBoolState(defaultValue: boolean): [boolean, Fn, Fn] {
  const [val, setVal] = useState(defaultValue);
  const setTrue = useConstantSetters(setVal, true);
  const setFalse = useConstantSetters(setVal, false);
  return [val, setTrue, setFalse];
}

export function useToggle(defaultValue: boolean): [boolean, Fn, (v: boolean) => void] {
  const [val, setVal] = useState(defaultValue);
  const toggle = useCallback(() => {
    setVal(!val);
  }, [val]);

  return [val, toggle, setVal];
}

export function useInputState<T extends string = string>(
  defaultValue: T,
): [T, EventHandlerFn, (value: T) => void];
export function useInputState<T extends string = string>(
  defaultValue: T | null,
  treatEmptyAsNull: true,
): [T | null, EventHandlerFn, (value: T) => void];
export function useInputState<T extends string = string>(
  defaultValue: T | null,
  treatEmptyAsNull?: boolean,
): [T | null, EventHandlerFn, (value: T) => void] {
  const [value, setValue] = useState<T | null>(defaultValue);
  const setter = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputVal = event.currentTarget.value as T;
      setValue(treatEmptyAsNull && inputVal === '' ? null : inputVal);
    },
    [treatEmptyAsNull],
  );

  return [value, setter, setValue];
}

export function useCheckboxState(
  defaultValue: boolean,
): [boolean, EventHandlerFn, (value: boolean) => void] {
  const [value, setValue] = useState(defaultValue);
  const setter = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.checked);
  }, []);

  return [value, setter, setValue];
}

export function useNumericInputState(
  defaultValue: number,
  explicitCoercion = false,
): [number, EventHandlerFn, (value: number) => void] {
  const [value, setValue] = useState(defaultValue);
  const setter = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(
        explicitCoercion
          ? Number.parseInt(event.currentTarget.value, 10)
          : event.currentTarget.valueAsNumber,
      );
    },
    [explicitCoercion],
  );

  return [value, setter, setValue];
}

export function useNullishNumericInputState(
  defaultValue: number | null,
  explicitCoercion = false,
): [number | null, EventHandlerFn, (value: number | null) => void] {
  const [value, setValue] = useState(defaultValue);
  const setter = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(
        explicitCoercion
          ? Number.parseInt(event.currentTarget.value, 10)
          : event.currentTarget.valueAsNumber,
      );
    },
    [explicitCoercion],
  );

  return [value, setter, setValue];
}

export function useTimeoutCreator(): (callback: () => void, ms: number) => void {
  const timeouts = useRef<number[]>([]);

  useEffect(() => {
    return () => {
      for (const timeout of timeouts.current) {
        clearTimeout(timeout);
      }
    };
  }, []);

  const cb = useCallback(
    (callback: () => void, ms: number) => {
      timeouts.current.push(setTimeout(callback, ms));
    },
    [timeouts],
  );

  return cb;
}

export const useTypeSequence = (
  sequence: string,
  terminator: string,
  callback: (val: string) => void,
  deps: unknown[],
): void => {
  const wrapped = useCallback(callback, [callback, ...deps]);
  useEffect(() => {
    let recorded = '';
    const listener = (ev: KeyboardEvent) => {
      if (ev.key !== terminator) {
        recorded += ev.key;
      }

      if (recorded.length <= sequence.length) {
        if (!sequence.startsWith(recorded)) {
          recorded = '';
        }

        if (ev.key === terminator) {
          recorded = '';
        }
      } else {
        if (!recorded.startsWith(sequence)) {
          recorded = '';
          return;
        }

        if (ev.key === terminator) {
          wrapped(recorded);
          recorded = '';
        }
      }
    };

    window.addEventListener('keypress', listener);

    return () => {
      window.removeEventListener('keypress', listener);
    };
  }, [sequence, wrapped, terminator]);
};

export function emptyFallback<T>(val: string | null | undefined, fallback: T): T | string {
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  return val || fallback;
}

export function formatBytes(bytes: number, decimals = 2): string {
  if (!bytes || bytes < 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${Number.parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export const shouldDisableHotkeys = (): boolean => {
  return document.getElementsByClassName('modal').length > 0;
};

interface MergedOption {
  name: string;
  id?: number;
  optionId?: number;
}

export const handleUnknownOptionError = (error: APIError, optionList?: MergedOption[]): void => {
  const optId = error.apiResponse.errors.logic!.find(
    (e) => e.code === LogicError.UnknownProductOption,
  )!.args.optionId as number;

  const opt = optionList?.find((o) => {
    if (o.id) {
      return o.id === optId;
    }

    if (o.optionId) {
      return o.optionId === optId;
    }

    return false;
  });

  toast.error(`Invalid option: ${opt?.name ?? `unknown with ID ${optId}`}`);
};
