import React, { FC } from 'react';
import * as OrderSummary from '../../shared/orders/model';
import { useTranslation } from '../translations';

interface OptionViewerProps {
  readonly optionOrderItems: OrderSummary.OrderItemOption[];
}

export const OptionViewer: FC<OptionViewerProps> = ({ optionOrderItems }) => {
  const { currency } = useTranslation();
  return (
    <>
      {optionOrderItems.map((option) => {
        const { type, priceModifier, value, name, valueDisplayName } = option;

        return (
          <div className="order-item-option" key={option.optionId}>
            <div className="order-item-option-body">
              <strong>{name}</strong>:{' '}
              {valueDisplayName ??
                (type === 'currency' ? currency(Number.parseInt(value!, 10)) : value)}
              {priceModifier > 0 && ` (${currency(priceModifier)})`}
            </div>
          </div>
        );
      })}
    </>
  );
};
