import React, { FC } from 'react';
import { Badge } from 'reactstrap';
import { Option } from '../../../../shared/options';
import { useTranslation } from '../../../translations';
import { truncateText } from '../../../utils';

interface ProductOptionBadgeProps {
  readonly option: Option;
  readonly required?: boolean;
}

export const ProductOptionBadges: FC<ProductOptionBadgeProps> = ({
  option: { type, min, max, selectValues },
  required,
}) => {
  const { currency } = useTranslation();
  const fields: string[] = [];

  if (required) {
    fields.push('Required');
  }

  switch (type) {
    case 'file':
    case 'phone': {
      break;
    }

    case 'multi':
    case 'select': {
      if (type === 'multi') {
        fields.push(`Max: ${max!}`);
      }

      if (selectValues) {
        fields.push(
          `Values: ${truncateText(
            selectValues.map((v) => v.displayName ?? v.value).join(', '),
            64,
          )}`,
        );
      }

      break;
    }

    case 'currency':
    case 'number':
    case 'text': {
      const isCurrency = type === 'currency';
      if (isCurrency) {
        fields.push('Symbol: $ USD');
      }

      fields.push(`Min: ${isCurrency ? currency(min) : min}`);
      if (max) {
        fields.push(`Max: ${isCurrency ? currency(max) : max}`);
      }

      break;
    }
  }

  return (
    <span className="badge-list">
      {fields.map((field) => (
        <Badge color="secondary" key={field} pill>
          {field}
        </Badge>
      ))}
    </span>
  );
};
