import { DndContext, DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, FormGroup, Input, Label, Table } from 'reactstrap';
import { MaterialIcon } from '..';
import { OptionInternal, OptionSelectValue, SourceConfig } from '../../../shared/options';
import { useNullishNumericInputState, useToggle } from '../../utils';
import { Currency } from '../Currency';
import { OptionEditorSelectAdd } from './OptionEditorSelectAdd';

interface OptionEditorSelectProps {
  readonly option: OptionInternal;
  readonly isMulti?: boolean;
  readonly setOption: Dispatch<SetStateAction<OptionInternal>>;
  setIsEditing(state: boolean): void;
}

function findIndex(pages: OptionSelectValue[], id: string) {
  return pages.findIndex((p) => p.value === id);
}

export const OptionEditorSelect: FC<OptionEditorSelectProps> = ({
  option,
  setOption,
  isMulti,
  setIsEditing,
}) => {
  const [isCreate, toggleCreate, setIsCreate] = useToggle(false);
  const [activeId, setActiveId] = useState<string | null>(null);
  const [max, setMax] = useNullishNumericInputState(option.id === 0 ? 50 : option.max);
  const [editKey, setEditKey] = useState<string | null>(null);
  const selectValues = option.selectValues ?? [];
  const config = SourceConfig[option.source];

  useEffect(() => {
    setOption((old) => ({ ...old, max }));
  }, [setOption, max]);

  const updateSelectValues = useCallback(
    (values: SetStateAction<OptionSelectValue[]>) => {
      setOption((old) => ({
        ...old,
        selectValues: typeof values === 'function' ? values(old.selectValues ?? []) : values,
      }));
    },
    [setOption],
  );

  const deleteSelectOption = useCallback(
    (value: string) => {
      updateSelectValues((old) => old.filter((so) => so.value !== value));
    },
    [updateSelectValues],
  );

  const onEdit = useCallback(
    (value: OptionSelectValue) => {
      const edittedValues: OptionSelectValue[] = selectValues.map((item) => {
        if (item.value === value.value) {
          return value;
        }

        return item;
      });

      updateSelectValues(edittedValues);
      setEditKey(null);
      setIsEditing(false);
    },
    [selectValues, updateSelectValues],
  );

  const onAddNew = useCallback(
    (value: OptionSelectValue) => {
      if (selectValues.some((t) => t.value === value.value)) {
        toast.error('The options value need to be unique');
        return;
      }

      updateSelectValues((old) => old.concat(value));
      setIsCreate(false);
    },
    [selectValues, updateSelectValues],
  );

  const onDragEnd = useCallback(
    ({ over }: DragEndEvent) => {
      setActiveId(null);
      if (over) {
        updateSelectValues((old) => {
          const activeIndex = activeId ? findIndex(old, activeId) : -1;
          const overIndex = findIndex(old, over.id as string);

          if (activeIndex !== overIndex) {
            return arrayMove(old, activeIndex, overIndex);
          }

          return old;
        });
      }
    },
    [activeId, setActiveId, updateSelectValues],
  );

  const onDragStart = useCallback(
    ({ active }: DragStartEvent) => {
      if (active) {
        setActiveId(active.id as string);
      }
    },
    [setActiveId],
  );

  return (
    <>
      <Col lg={6} xs={12}>
        {isMulti && (
          <FormGroup>
            <Label for="options">
              Max Number of Options <small>(Optional)</small>
            </Label>
            <Input defaultValue={max ?? ''} name="max" onChange={setMax} type="number" />
          </FormGroup>
        )}
      </Col>
      <Col lg={6} xs={12} />
      <Col xs={12}>
        <DndContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          <SortableContext items={selectValues.map((t) => t.value)}>
            <Label for="options">Select Options</Label>
            <Table>
              <thead>
                <tr>
                  <th />
                  <th>Value</th>
                  <th>Display Name (Optional)</th>
                  {config.allowPrice && <th>Price Modifier</th>}
                  <th style={{ width: '70px', textAlign: 'right' }}>
                    <Button
                      color={isCreate ? 'danger' : 'success'}
                      id={`addOptionValue${isCreate ? 'Cancel' : ''}`}
                      onClick={toggleCreate}
                      outline={!isCreate}
                    >
                      <MaterialIcon className="align-middle" name={isCreate ? 'close' : 'add'} />
                    </Button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {isCreate && (
                  <OptionEditorSelectAdd allowPrice={config.allowPrice} onCreate={onAddNew} />
                )}
                {selectValues.map((opt, idx) => {
                  const element =
                    opt.value === editKey ? (
                      <OptionEditorSelectAdd
                        allowPrice={config.allowPrice}
                        existing={opt}
                        onCreate={onEdit}
                      />
                    ) : (
                      <SelectItem
                        allowPrice={config.allowPrice}
                        deleteItem={deleteSelectOption}
                        enableEdit={(value) => {
                          setEditKey(value);
                          setIsEditing(true);
                        }}
                        idx={idx}
                        item={opt}
                        key={opt.value}
                      />
                    );

                  return element;
                })}
              </tbody>
            </Table>
          </SortableContext>
        </DndContext>
      </Col>
    </>
  );
};

interface SelectItemProps {
  readonly idx: number;
  readonly item: OptionSelectValue;
  readonly allowPrice: boolean;
  enableEdit(value: string): void;
  deleteItem(value: string): void;
}

const SelectItem: FC<SelectItemProps> = ({ item, enableEdit, deleteItem, idx, allowPrice }) => {
  const { attributes, isDragging, listeners, setNodeRef, transition, transform } = useSortable({
    id: item.value,
  });

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? 0.5 : undefined,
  };

  return (
    <tr id={`selectValue${item.value ?? idx}`} ref={setNodeRef} style={style}>
      <td style={{ width: '50px' }}>
        <div className="handlebar" {...attributes} {...listeners} style={{ float: 'left' }}>
          <MaterialIcon name="drag_indicator" />
        </div>
      </td>
      <td>{item.value}</td>
      <td>{item.displayName}</td>
      {allowPrice && (
        <td>
          <Currency value={item.priceModifier} />
        </td>
      )}
      <td className="text-right">
        <MaterialIcon
          className="pointer edit"
          name="edit"
          onClick={() => {
            enableEdit(item.value);
          }}
        />
        <MaterialIcon
          className="pointer delete"
          name="delete"
          onClick={() => {
            deleteItem(item.value);
          }}
        />
      </td>
    </tr>
  );
};
