import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { Link, NavLink, Route, RouteComponentProps, Switch, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Badge,
  Button,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Nav,
  PopoverBody,
  Row,
  UncontrolledPopover,
} from 'reactstrap';
import { ProductModel } from '../../../../shared/orders';
import {
  CreateVoucherModel,
  VoucherListItem,
  VoucherWithDetails,
} from '../../../../shared/orders/voucher';
import {
  ActionButton,
  Breadcrumb,
  ElementSelector,
  Filter,
  FilterTable,
  MaterialIcon,
  PageHeader,
  PermissionBoundary,
  UserStateComponent,
} from '../../../components';
import { Chip } from '../../../components/Chip';
import { Currency } from '../../../components/Currency';
import { DateTime } from '../../../components/Date';
import {
  migrateTimezone,
  NullableTimeZoneInputField,
  TimeZoneInputField,
  trimTime,
} from '../../../components/TimeZoneInputField';
import { UserSelector } from '../../../components/UserSelector';
import {
  displayName,
  Fetcher,
  renderName,
  TableKVPair,
  useConvention,
  useFetcher,
  useInputModel,
  useObject,
} from '../../../utils';
import { LoadingState } from '../../../utils/LoadingState';
import { LoadingWrapper } from '../../../utils/LoadingWrapper';
import { captureError } from '../../../utils/errorHandling';
import { charSets, randomString } from '../../../utils/random';
import { AuditObjectPage } from '../audit/log';
import { LocalSearchProvider } from './utils';

export type VoucherProduct = Pick<ProductModel, 'description' | 'displayName' | 'id' | 'name'>;

interface VoucherResponse {
  successCount: number;
}

const filterTableFilters: Filter<VoucherListItem>[] = [
  {
    displayName: 'State',
    name: 'state',
    options: [
      {
        filter: (voucher) => voucher.maxUses !== -1 && voucher.orderCount >= voucher.maxUses,
        name: 'Fully Claimed',
        value: 'claimed',
      },
      {
        filter: (voucher) => voucher.maxUses === -1 || voucher.orderCount < voucher.maxUses,
        name: 'Available',
        value: 'available',
      },
      {
        filter: (voucher) => !!voucher.deletedAt,
        name: 'Deleted / Archived',
        value: 'deleted',
      },
      {
        filter: (voucher) => voucher.internalOnly,
        name: 'Internal Vouchers',
        value: 'internal',
      },
    ],
  },
];

export const VoucherComponent: FC<RouteComponentProps> = ({ match }) => (
  <UserStateComponent>
    <PageHeader>Vouchers</PageHeader>
    <Switch>
      <Route component={VoucherComponentTable} exact path={`${match.path}`} />
      <Route component={CreateVoucher} exact path={`${match.path}/new`} />
      <Route component={VoucherDetails} path={`${match.path}/:id`} />
    </Switch>
  </UserStateComponent>
);

const VoucherComponentTable: FC<RouteComponentProps> = ({ match }) => {
  const fetcher = useFetcher(async () => {
    return await api.getVouchers();
  });

  if (!fetcher.complete) {
    return <Fetcher result={fetcher} />;
  }

  return (
    <>
      <Link className="button-link" to={`${match.path}/new`}>
        <Button color="primary" id="createVoucher">
          Create New Voucher
        </Button>
      </Link>
      <hr />
      <FilterTable<VoucherListItem>
        data={fetcher.data!}
        filters={filterTableFilters}
        infoUrl={match.path}
        renderer={(v) => <VoucherElement voucher={v} />}
      />
    </>
  );
};

const VoucherElement: FC<{ readonly voucher: VoucherListItem }> = ({ voucher }) => {
  return (
    <div className="table-item">
      <MaterialIcon className="icon" large name="loyalty" />
      <p>
        {voucher.displayName ? `${voucher.displayName} (${voucher.code})` : voucher.code} &mdash;{' '}
        <Currency value={voucher.amount} />
      </p>
      <VoucherBadges voucher={voucher} />
    </div>
  );
};

const VoucherBadges: FC<{
  readonly voucher: VoucherListItem;
}> = ({ voucher }) => {
  const badges: JSX.Element[] = [];

  if (voucher.deletedAt) {
    return (
      <Badge color="secondary" pill>
        Deleted / Archived
      </Badge>
    );
  }

  if (voucher.maxUses !== -1 && voucher.maxUses - voucher.orderCount <= 0) {
    badges.push(
      <Badge color="danger" pill>
        Fully Claimed (0 / {voucher.maxUses})
      </Badge>,
    );
  } else {
    badges.push(
      <Badge color="success" pill>
        Available ({voucher.orderCount} / {voucher.maxUses === -1 ? 'Unlimited' : voucher.maxUses})
      </Badge>,
    );
  }

  if (voucher.availableTo !== null && voucher.availableTo >= new Date()) {
    badges.push(
      <Badge color="warning" pill>
        Expired ({voucher.availableTo.toLocaleDateString()})
      </Badge>,
    );
  }

  if (voucher.canBeStacked) {
    badges.push(
      <Badge color="info" pill>
        Can Be Stacked
      </Badge>,
    );
  }

  if (voucher.internalOnly) {
    badges.push(
      <Badge color="info" pill>
        Internal
      </Badge>,
    );
  }

  return (
    <span className="badge-list compact">
      {badges.map((badge) => {
        return <>{badge} </>;
      })}
    </span>
  );
};

const VoucherDetails: FC<RouteComponentProps<{ id: string }>> = ({ match }) => (
  <LoadingWrapper
    dataFetcher={async () => await api.getVoucher(Number.parseInt(match.params.id, 10))}
  >
    {(voucher, refresh) => (
      <div>
        <Breadcrumb
          items={[
            { text: 'Vouchers', url: '/housekeeping/vouchers' },
            { text: voucher.code, url: match.path, active: true },
          ]}
        />
        <Nav tabs>
          <NavLink activeClassName="active" className="nav-link" exact to={match.url}>
            Summary
          </NavLink>
          <NavLink activeClassName="active" className="nav-link" exact to={`${match.url}/orders`}>
            Orders
          </NavLink>
          <PermissionBoundary inline requiredPermissions={['audit:read']}>
            <NavLink
              activeClassName="active"
              className="nav-link"
              exact
              to={`${match.url}/auditlogs`}
            >
              Audit
            </NavLink>
          </PermissionBoundary>
          {voucher.deletedAt === null && (
            <NavLink activeClassName="active" className="nav-link" exact to={`${match.url}/issue`}>
              Issue
            </NavLink>
          )}
          {voucher.deletedAt === null && (
            <NavLink activeClassName="active" className="nav-link" exact to={`${match.url}/delete`}>
              Delete
            </NavLink>
          )}
        </Nav>
        <Switch>
          <Route
            exact
            path={`${match.path}/orders`}
            render={() => <VoucherOrders voucher={voucher} />}
          />
          <Route
            exact
            path={`${match.path}/issue`}
            render={() => <VoucherIssue refresh={refresh} voucher={voucher} />}
          />
          <Route
            exact
            path={`${match.path}/auditlogs`}
            render={() => <AuditObjectPage entityId="Voucher" objectId={voucher.id.toString()} />}
          />
          {voucher.deletedAt === null && (
            <Route
              exact
              path={`${match.path}/delete`}
              render={() => <VoucherDelete refresh={refresh} voucher={voucher} />}
            />
          )}
          <Route render={() => <VoucherDetailsSummary voucher={voucher} />} />
        </Switch>
      </div>
    )}
  </LoadingWrapper>
);

const VoucherDetailsSummary: FC<{ readonly voucher: VoucherWithDetails }> = ({ voucher }) => (
  <table className="table" id="voucherSummary">
    <tbody>
      <TableKVPair displayName="Value" name="amount" value={<Currency value={voucher.amount} />} />
      <TableKVPair
        displayName="Available From"
        name="availableFrom"
        value={voucher.availableFrom.toLocaleString()}
      />
      <TableKVPair
        displayName="Available To"
        name="availableTo"
        value={voucher.availableTo ? voucher.availableTo.toLocaleString() : <i>N/A</i>}
      />
      <TableKVPair displayName="Code" name="code" value={voucher.code} />
      <TableKVPair displayName="Issuer ID" name="issuer" value={voucher.issuerId?.toString()} />
      <TableKVPair
        displayName="Max Number of Uses"
        name="maxUses"
        value={voucher.maxUses.toString()}
      />
      <TableKVPair
        displayName="Can Voucher Be Stacked?"
        name="canBeStacked"
        value={voucher.canBeStacked ? 'Yes' : 'No'}
      />
      <TableKVPair
        displayName="Total Uses"
        name="totalUses"
        value={(voucher.orders ?? []).length.toString()}
      />
      <TableKVPair
        displayName="Created At"
        name="createdAt"
        value={voucher.createdAt.toLocaleString()}
      />
      {voucher.productsRestriction.length > 0 && (
        <TableKVPair
          displayName="Product Restrictions"
          name="productsRestriction"
          value={voucher.productsRestriction.map((p) => (
            <Link key={p.id} to={`/housekeeping/products/${p.id}`}>
              <Chip<number> id={`productChip${p.id}`} value={p.id}>
                {`${displayName(p)} (ID: ${p.id})`}
              </Chip>{' '}
            </Link>
          ))}
        />
      )}
    </tbody>
  </table>
);

const VoucherOrders: FC<{ readonly voucher: VoucherWithDetails }> = ({ voucher }) => (
  <table className="table">
    <thead>
      <tr>
        <th>User</th>
        <th>Order Id</th>
        <th>Order Status</th>
        <th>Paid At</th>
      </tr>
    </thead>
    <tbody>
      {voucher.orders.map((order) => (
        <tr key={order.id}>
          <td>
            <Link to={`/housekeeping/attendees/user/${order.user.id}`}>
              {renderName(order.user)}
            </Link>
          </td>
          <td>
            <Link to={`/housekeeping/orders/${order.id}`}>{order.id}</Link>
          </td>
          <td>{order.status}</td>
          <td>{order.paidAt && <DateTime value={order.paidAt} />}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

const VoucherIssue: FC<{ readonly voucher: VoucherWithDetails; readonly refresh: () => void }> = ({
  voucher,
  refresh,
}) => {
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const onVoucherIssue = useCallback(
    ({ successCount }: VoucherResponse) => {
      if (successCount > 0) {
        toast.success(`Voucher #${voucher.id} has been issued to ${successCount} user(s).`);
      }

      refresh();
    },
    [refresh, voucher],
  );

  return (
    <JSONForm<VoucherResponse, { userIds: number[] }>
      method="post"
      onSuccess={onVoucherIssue}
      path={`/api/vouchers/${voucher.id}/issue`}
      preSubmit={(r) => (r.inputs!.userIds = selectedUsers)}
    >
      <p>
        This will issue the voucher #{voucher.id} to all of the selected users for their current
        order.
      </p>
      <FormGroup>
        <Label for="otherRecipients">User(s)</Label>
        <UserSelector id="customRecipients" selectionIdsChanged={setSelectedUsers} />
      </FormGroup>
      <FormGroup id="fulfillOrdersGroup">
        <div className="custom-control custom-checkbox margin-top-10 left-align">
          <Input
            className="custom-control-input"
            id="fulfillOrders"
            name="fulfillOrders"
            type="checkbox"
          />
          <Label className="custom-control-label" for="fulfillOrders">
            Attempt to fulfill associated orders automatically.
          </Label>
          <UncontrolledPopover placement="bottom" target="fulfillOrdersGroup" trigger="hover">
            <PopoverBody>
              <p>This option will attempt to fulfill the latest order of each user listed above.</p>
              <p>
                Where the remaining balance is still greater than zero, or there are no items in the
                user's cart, the order will not be fulfilled.
              </p>
            </PopoverBody>
          </UncontrolledPopover>
        </div>
      </FormGroup>

      <Button color="primary" id="issueVoucherBtn" type="submit">
        Issue Voucher
      </Button>
    </JSONForm>
  );
};

const VoucherDelete: FC<{ readonly voucher: VoucherWithDetails; readonly refresh: () => void }> = ({
  voucher,
  refresh,
}) => (
  <div>
    <p>Are you sure you want to delete voucher {voucher.displayName ?? voucher.code}?</p>
    <ActionButton
      action={`/api/vouchers/${voucher.id}`}
      color="danger"
      id="completeDelete"
      method="delete"
      onSuccess={() => {
        toast.success('Voucher has been deleted successfully.');
        refresh();
      }}
    >
      Delete
    </ActionButton>
  </div>
);

function randomVoucher(): string {
  return `${randomString(5, charSets.code)}-${randomString(5, charSets.code)}-${randomString(
    5,
    charSets.code,
  )}`;
}

const CreateVoucher: FC = () => {
  const history = useHistory();
  const { timeZone } = useConvention();
  const [voucher, setVoucher] = useState<CreateVoucherModel>(() => ({
    canBeStacked: false,
    code: randomVoucher(),
    displayName: '',
    amount: 0,
    maxUses: -1,
    availableFrom: new Date(),
    availableTo: null,
    productsRestriction: [],
  }));

  const defaultDate = migrateTimezone(trimTime(new Date()), timeZone);
  const setVoucherCodeEV = useInputModel(setVoucher, 'code');
  const seDisplayName = useInputModel(setVoucher, 'displayName');
  const setAmount = useInputModel(setVoucher, 'amount');
  const setMaxUses = useInputModel(setVoucher, 'maxUses');
  const [, setAvailableFrom] = useObject(voucher, setVoucher, 'availableFrom');
  const [, setAvailableTo] = useObject(voucher, setVoucher, 'availableTo');
  const setCanBeStacked = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setVoucher((old) => ({ ...old, canBeStacked: e.target.checked })),
    [setVoucher],
  );

  const setRandomVoucherCode = useCallback(() => {
    setVoucher((old) => ({ ...old, code: randomVoucher() }));
  }, [setVoucher]);

  const submit = useCallback(async () => {
    try {
      const { id } = await api.createVoucher({
        ...voucher,
        displayName: voucher.displayName?.length === 0 ? null : voucher.displayName,
      });

      toast.success('New voucher has been created!');
      history.push(`/housekeeping/vouchers/${id}`);
    } catch (error) {
      captureError(error as Error);
    }
  }, [voucher]);

  const productList = useFetcher(async () => {
    return await api.getProducts();
  }, []);

  const memoizedSearchProvider = useMemo(
    () => new LocalSearchProvider(async () => productList.data ?? []),
    [productList.data],
  );

  if (!productList.complete) {
    return <Fetcher result={productList} />;
  }

  return (
    <>
      <Breadcrumb
        items={[
          { text: 'Vouchers', url: '/housekeeping/vouchers' },
          { text: 'Create', url: '/housekeeping/vouchers/new', active: true },
        ]}
      />
      <Row id="createVoucherForm">
        <Col lg={6} xs={12}>
          <FormGroup>
            <Label for="name">Voucher Code</Label>
            <div className="input-group">
              <div className="input-group-prepend">
                <Button color="primary" onClick={setRandomVoucherCode} type="button">
                  <MaterialIcon name="casino" small />
                </Button>
              </div>
              <Input
                id="code"
                onChange={setVoucherCodeEV}
                placeholder="Voucher Code"
                required
                value={voucher.code}
              />
            </div>
          </FormGroup>
        </Col>
        <Col lg={6} xs={12}>
          <FormGroup>
            <Label for="displayName">
              Display Name <small>(Optional)</small>
            </Label>
            <Input id="displayName" onChange={seDisplayName} value={voucher.displayName ?? ''} />
          </FormGroup>
        </Col>
        <Col lg={6} xs={12}>
          <FormGroup>
            <Label for="name">Amount</Label>
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">$</div>
              </div>
              <Input
                id="amount"
                min={0.01}
                onChange={setAmount}
                required
                step={0.01}
                type="number"
                value={voucher.amount}
              />
            </div>
          </FormGroup>
        </Col>
        <Col lg={6} xs={12}>
          <FormGroup>
            <Label for="name">Max Uses</Label>
            <Input
              id="maxUses"
              min={-1}
              onChange={setMaxUses}
              required
              type="number"
              value={voucher.maxUses}
            />
            <FormText color="muted">For unlimited uses, use -1.</FormText>
          </FormGroup>
        </Col>
        <Col lg={6} xs={12}>
          <FormGroup>
            <Label for="availableFrom">Available From</Label>
            <TimeZoneInputField
              defaultValue={voucher.availableFrom}
              id="availableFrom"
              onChange={setAvailableFrom}
              required
              timeZone={timeZone}
            />
          </FormGroup>
        </Col>
        <Col lg={6} xs={12}>
          <FormGroup>
            <Label for="availableTo">Available Until</Label>
            <NullableTimeZoneInputField
              defaultValue={voucher.availableTo}
              id="availableTo"
              min={defaultDate}
              onChange={setAvailableTo}
              timeZone={timeZone}
            />
          </FormGroup>
        </Col>
        <Col lg={6} xs={12}>
          <FormGroup>
            <Label for="availableTo">
              Restrict voucher to products
              <>{productList.state === LoadingState.Loading && <>Loading...</>}</>
            </Label>
            <ElementSelector<VoucherProduct>
              defaultSelected={[]}
              id="productSelector"
              searchPlaceholder="Search products by name or ID"
              searchProvider={memoizedSearchProvider}
              selectionChanged={(t) =>
                setVoucher({ ...voucher, productsRestriction: t.map((p) => p.id) })
              }
            />
          </FormGroup>
        </Col>
        <Col lg={6} xs={12}>
          <FormGroup>
            <div className="custom-control custom-checkbox margin-top-5">
              <Input
                className="custom-control-input"
                defaultChecked={false}
                id="canBeStacked"
                onChange={setCanBeStacked}
                type="checkbox"
              />
              <Label className="custom-control-label" for="canBeStacked">
                Allow Voucher Stacking
              </Label>
            </div>
            <FormText className="margin-top-10" color="muted">
              Voucher Stacking allows users to use multiple vouchers at once. All vouchers being
              used by the user must be stackable for this to work.
            </FormText>
          </FormGroup>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg={8} xs={12}>
          <Button block color="primary" id="submitNewVoucher" onClick={submit}>
            Create Voucher
          </Button>
        </Col>
      </Row>
    </>
  );
};
