import React, { FC, useState } from 'react';
import { Badge, Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { OrderItemProduct } from '..';
import * as OrderSummary from '../../../shared/orders/model';
import {
  MaterialIcon,
  OrderItemElement,
  OrderItemSelectOption,
  RefundRequestModal,
  UserStateComponent,
} from '../../components';
import { Currency } from '../../components/Currency';
import { PageHeader } from '../../components/PageHeader';
import { Fetcher, useFetcher, useUser } from '../../utils';

export const OrderHistoryComponent: FC = () => {
  const user = useUser()!;
  const [refundOrder, setRefundOrder] = useState<OrderSummary.Order | undefined>(undefined);
  const [modalOption, setModalOption] = useState<OrderItemSelectOption | undefined>(undefined);

  const fetcher = useFetcher(async () => {
    const [categories, orders] = await Promise.all([
      await api.getProductCategories(),
      await api.getOrdersByUserId(user.id).then((order) => order.sort((a, b) => b.id - a.id)),
    ]);

    return { categories, orders };
  }, [user]);

  if (!fetcher.complete) {
    return (
      <UserStateComponent>
        <Fetcher result={fetcher} />
      </UserStateComponent>
    );
  }

  return (
    <UserStateComponent>
      <PageHeader>Order History</PageHeader>
      <Row className="justify-content-center" id="orderHistory">
        {fetcher.data!.orders.length === 0 ? (
          <Col className="text-center" id="noOrderHistory" lg={8} xs={12}>
            <MaterialIcon large name="sentiment_dissatisfied" />
            <h4>You've got no previous orders.</h4>
            <p>Once you pay for an order, you can view and manage it here.</p>
          </Col>
        ) : (
          <>
            {fetcher.data?.orders
              .filter(({ orderItems }) => orderItems && orderItems.length > 0)
              .map((order) => (
                <Col key={order.id} lg={8} xs={12}>
                  <OrderItemElement
                    categories={fetcher.data!.categories}
                    hasRefunds={order.refunds && order.refunds.length > 0}
                    id={`order_${order.id}`}
                    isCustomer
                    onSelect={(opt) => {
                      setModalOption(opt);
                      setRefundOrder(order);
                    }}
                    order={order}
                  />
                </Col>
              ))}
            {refundOrder && modalOption === 'requestRefund' && (
              <RefundRequestModal
                onCancel={() => {
                  setModalOption(undefined);
                  setRefundOrder(undefined);
                }}
                onSuccess={() => {
                  setModalOption(undefined);
                  setRefundOrder(undefined);
                  fetcher.refresh();
                }}
                order={refundOrder}
              />
            )}
            {refundOrder && modalOption === 'viewRefundHistory' && (
              <OrderHistoryModal
                order={refundOrder}
                setModalOption={setModalOption}
                setOrder={setRefundOrder}
              />
            )}
          </>
        )}
      </Row>
    </UserStateComponent>
  );
};

const OrderHistoryModal: FC<{
  readonly order?: OrderSummary.Order;
  setOrder(state: OrderSummary.Order | undefined): void;
  setModalOption(state: OrderItemSelectOption | undefined): void;
}> = ({ order, setOrder, setModalOption }) => {
  return (
    <Modal className="modal-large" id="refundModal" isOpen>
      <ModalHeader>Refund History: Order #{order!.id}</ModalHeader>
      <ModalBody className="table">
        {order!.refunds.map((refund) => (
          <div className="table-item" key={refund.id}>
            <h4>Refund #{refund.id}</h4>
            {refund.status === 'pending' && <Badge color="warning">Pending Approval</Badge>}
            {refund.status === 'denied' && <Badge color="danger">Denied</Badge>}
            {refund.status === 'approved' && (
              <>
                <Badge color="success">Approved</Badge>
                <small>
                  &mdash; <Currency value={refund.amount} /> Refunded
                </small>
              </>
            )}
            <br />
            <br />
            <hr />
            {refund.items.map(({ itemId }) => {
              const item = order!.orderItems.find(({ id }) => id === itemId)!;
              return <OrderItemProduct item={item} key={item.id} order={order!} readOnly />;
            })}
          </div>
        ))}
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          onClick={() => {
            setModalOption(undefined);
            setOrder(undefined);
          }}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};
