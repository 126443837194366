import React, { FC, ReactNode } from 'react';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';
import { Order } from '../../shared/orders/model';
import { InfoCard, PageHeader, UserStateComponent } from '../components';
import { RegistrationInfo } from '../models';
import { useTranslation } from '../translations';
import { isLogicError, isResourceError, useUser } from '../utils';
import { LoadingWrapper } from '../utils/LoadingWrapper';
import { LogicError } from '../utils/errorHandling';

export const Dashboard: FC = () => {
  const user = useUser();

  return (
    <UserStateComponent>
      <PageHeader>Dashboard</PageHeader>
      <Row className="justify-content-center" id="dashboard">
        <InfoCard
          heading="Help"
          icon="help"
          status="info"
          text={[
            'You can navigate through the system using the navigation bar at the side of each page.',
            'Important notices and items you may need to complete will appear here.',
          ]}
        />
        <LoadingWrapper<Order, void>
          dataFetcher={async () => await api.getActiveOrder(user!.id)}
          errorDisplay={(err) => isResourceError(err, 'Order') && null}
          inline
        >
          {(data) => <OrderStatus order={data} />}
        </LoadingWrapper>
        <LoadingWrapper<RegistrationInfo, void>
          dataFetcher={async () => await api.getUserActiveRegistration(user!.id)}
          errorDisplay={handleRegistrationError}
          inline
        >
          {(data) => <RegistrationStatus registration={data} />}
        </LoadingWrapper>
      </Row>
      <hr />
      <Card>
        <CardHeader className="card-color warning" />
        <CardBody className="text-center margin-top-10">
          <small>
            <p>
              If your details have changed since you last attended, it's very important that you
              take the time to check and review your personal details.
            </p>
            <p>An incorrect name or address could mean you're unable to attend the convention!</p>
          </small>
        </CardBody>
      </Card>
    </UserStateComponent>
  );
};

function handleRegistrationError(error: Error): ReactNode {
  if (isLogicError(error, LogicError.RegistrationClosed)) {
    // Used for testing
    return <div id="registrationClosed" />;
  }

  if (isResourceError(error, 'Registration')) {
    return <CreateRegistration />;
  }

  return undefined;
}

const CreateRegistration: FC = () => {
  return (
    <InfoCard
      button={{
        status: 'primary',
        text: 'Create registration',
        to: '/event/register/new',
      }}
      heading="Register"
      icon="how_to_reg"
      id="createRegistration"
      status="info"
      text={[`You have not yet created a registration.`]}
    />
  );
};

const RegistrationStatus: FC<{ readonly registration: RegistrationInfo }> = ({ registration }) => {
  return (
    <InfoCard
      button={{
        status: 'outline-primary',
        text: 'Update registration',
        to: '/event/register/edit',
      }}
      heading={registration.paidOrderItem ? 'Registration complete' : 'Registration pending'}
      icon="how_to_reg"
      id="updateRegistration"
      status={registration.paidOrderItem ? 'info' : 'warning'}
      text={
        registration.paidOrderItem
          ? [`Registration is complete and ready to go.`]
          : ['Registration is not yet active.']
      }
    />
  );
};

const OrderStatus: FC<{ readonly order: Order }> = ({ order }) => {
  const { currency } = useTranslation();
  if (!order.orderItems || order.orderItems.length === 0) {
    return null;
  }

  const total = order.breakdown.total - order.breakdown.paid;

  if (total <= 0) {
    return null;
  }

  return (
    <InfoCard
      button={{
        status: 'outline-danger',
        text: 'Pay Now',
        to: '/pay',
      }}
      heading="Outstanding Balance"
      icon="attach_money"
      id="outstandingBalance"
      status="danger"
      text={[`You currently have an outstanding balance of ${currency(total)}.`]}
    />
  );
};
