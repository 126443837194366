import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useConfig } from '../utils';
import en_us from './en-US.json';

type TranslationKey = keyof typeof en_us;

const full_dictionaries = {
  'en-US': en_us,
} as const satisfies Readonly<Record<string, Partial<Record<TranslationKey, string>>>>;

const short_dictionaries = {
  en: en_us,
} as const satisfies Readonly<Record<string, Partial<Record<TranslationKey, string>>>>;

const dictionaries = {
  ...full_dictionaries,
  ...short_dictionaries,
} as const satisfies Readonly<Record<string, Partial<Record<TranslationKey, string>>>>;

const availableLanguages = Object.entries(full_dictionaries).map(([k, v]) => ({
  key: k,
  label: v.language_name,
}));

function translator_generator_react(
  language: string,
  i18nOverrides?: Record<string, string>,
): (key: TranslationKey, ...params: ReactNode[]) => ReactNode[] {
  const dictionary: Record<TranslationKey, string> = (dictionaries[
    language as keyof typeof dictionaries
  ] ??
    dictionaries[language.split('-')[0] as keyof typeof dictionaries] ??
    en_us) as Record<TranslationKey, string>;

  return (key: TranslationKey, ...params: ReactNode[]) => {
    const translated: string = i18nOverrides?.[key] ?? dictionary[key] ?? key;
    const params_pct = Object.fromEntries(params.map((v, i) => [`%%${i}`, v]));
    const fixed_texts = Array.from(translated.split(/%%\d/g));
    const mobile_texts = Array.from(translated.match(/%%\d/g) ?? []);

    const nodes = fixed_texts.flatMap((v, i) => [v, params_pct[mobile_texts[i]]]).slice(0, -1);

    return nodes;
  };
}

interface useTranslationReturn {
  readonly t: (key: TranslationKey, ...params: ReactNode[]) => ReactNode[];
  readonly language: string;
  readonly setLanguage: (language: string) => void;
  readonly currency: (cents: number) => string;
  readonly availableLanguages: { key: string; label: string }[];
}

export function useTranslation(): useTranslationReturn {
  const { i18nOverrides, currencySymbol } = useConfig();
  const [language, setLanguage] = useState<string>(
    (window && window.localStorage ? window.localStorage.getItem('language') : null) ||
      (window && window.navigator ? window.navigator.language : null) ||
      'en-US',
  );

  const setLanguage_ = useCallback(
    (newLanguage: string) => {
      window.localStorage.setItem('language', newLanguage);
      setLanguage(newLanguage);
      window.location.reload();
    },
    [setLanguage],
  );

  const currency = useCallback(
    (cents: number) => {
      return `${currencySymbol}${(cents / 100).toFixed(2)}`;
    },
    [currencySymbol],
  );

  const translator_react = useMemo(
    () => translator_generator_react(language, i18nOverrides),
    [i18nOverrides, language],
  );

  return {
    t: translator_react,
    language,
    currency,
    setLanguage: setLanguage_,
    availableLanguages,
  };
}
