import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { Input } from 'reactstrap';
import { InputType } from 'reactstrap/es/Input';

type PickByType<T, Value> = {
  [P in keyof T as T[P] extends Value | undefined ? P : never]: T[P];
};

/*
 * Sometimes useState can have complex objects
 * For example: const [a, setA] = useState<{inner: {value: number}}>();
 * this function allows to bring out the inner objects into a new getter/setter
 * const [inner, setInner] = useObject(a, setA, 'inner');
 */
export function useObject<T extends object, U extends keyof T>(
  object: T,
  setter: Dispatch<(prevState: T) => T>,
  key: U,
): [T[U], Dispatch<SetStateAction<T[U]>>] {
  const setPayload = useCallback(
    (newPayload: SetStateAction<T[U]>) => {
      if (typeof newPayload === 'function') {
        const func = newPayload as (prevState: T[U]) => T[U];
        setter((old) => ({
          ...old,
          [key]: func(old[key]),
        }));
      } else {
        setter((old) => ({
          ...old,
          [key]: newPayload,
        }));
      }
    },
    [setter, key],
  );

  return [object[key], setPayload];
}

export type EventHandlerFn = (event: React.ChangeEvent<HTMLInputElement>) => void;
export function useInputModel<T extends object>(
  setter: Dispatch<(prevState: T) => T>,
  key: Extract<keyof T, string>,
): EventHandlerFn {
  const setType = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = (e.target.value as typeof key) || null;
      setter((old) => ({
        ...old,
        [key]: value,
      }));
    },
    [setter],
  );

  return setType;
}

export function useInputCheckboxModel<T extends object>(
  setter: Dispatch<(prevState: T) => T>,
  key: Extract<keyof T, string>,
): EventHandlerFn {
  const setType = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setter((old) => ({
        ...old,
        [key]: e.target.checked as unknown as typeof key,
      }));
    },
    [setter],
  );

  return setType;
}

function useInputStringNull<T extends object>(
  setter: Dispatch<(prevState: T) => T>,
  key: Extract<keyof PickByType<T, string | null>, string>,
): EventHandlerFn {
  const setType = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setter((old) => ({
        ...old,
        [key]: value === '' ? null : value,
      }));
    },
    [setter],
  );

  return setType;
}

interface ObjectInputFormProps<T> {
  object: T;
  setObject: React.Dispatch<React.SetStateAction<T>>;
  name: Extract<keyof T, string>;
  type?: InputType;
  disabled?: boolean;
  placeholder?: string;
  autoComplete?: string;
}

export const ObjectInputForm: <T extends object>(
  props: ObjectInputFormProps<T>,
) => React.ReactElement<ObjectInputFormProps<T>> = ({
  object,
  setObject,
  name,
  type,
  disabled,
  placeholder,
  autoComplete,
}) => {
  const onChange = useInputModel(setObject, name);

  const value = object[name] as string;
  return (
    <Input
      autoComplete={autoComplete}
      disabled={disabled}
      id={name as string}
      onChange={onChange}
      placeholder={placeholder}
      type={type}
      value={value}
    />
  );
};

interface InputStringProps<T> {
  object: T;
  setObject: React.Dispatch<React.SetStateAction<T>>;
  name: Extract<keyof PickByType<T, string>, string>;
  disabled?: boolean;
  placeholder?: string;
  autoComplete?: string;
}

export const InputString: <T extends object>(
  props: InputStringProps<T>,
) => React.ReactElement<InputStringProps<T>> = ({
  object,
  setObject,
  name,
  disabled,
  placeholder,
  autoComplete,
}) => {
  const onChange = useInputModel(setObject, name);

  const value = object[name] as string;
  return (
    <Input
      autoComplete={autoComplete}
      disabled={disabled}
      id={name as string}
      onChange={onChange}
      placeholder={placeholder}
      required
      value={value}
    />
  );
};

interface InputStringNullProps<T> {
  object: T;
  setObject: React.Dispatch<React.SetStateAction<T>>;
  name: Extract<keyof PickByType<T, string | null>, string>;
  disabled?: boolean;
  placeholder?: string;
  autoComplete?: string;
}

export const InputStringNull: <T extends object>(
  props: InputStringNullProps<T>,
) => React.ReactElement<InputStringNullProps<T>> = ({
  object,
  setObject,
  name,
  disabled,
  placeholder,
  autoComplete,
}) => {
  const onChange = useInputStringNull(setObject, name);

  const value = object[name] as string;
  return (
    <Input
      autoComplete={autoComplete}
      disabled={disabled}
      id={name as string}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
    />
  );
};
