import React from 'react';
import { SurchargeModel } from '../../../../shared/orders/product';
import { Currency } from '../../../components/Currency';

export const surchargeTitle = (surcharge: Pick<SurchargeModel, 'internal' | 'type'>): string => {
  if (surcharge.type === 'tax') {
    return 'Tax Rate';
  }

  return surcharge.internal ? 'Platform Fee' : 'Surcharge';
};

export const surchargeRateText = (
  surcharge: Pick<SurchargeModel, 'amount' | 'amountType' | 'type'>,
): React.ReactNode => {
  return surcharge.type === 'tax' || surcharge.amountType === 'percent' ? (
    `${surcharge.amount / 100}%`
  ) : (
    <Currency value={surcharge.amount} />
  );
};
