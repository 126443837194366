import React, { FC } from 'react';
import { ProductModel } from '../../shared/orders';
import { Currency } from './Currency';

interface PriceBreakdownProps {
  readonly product: ProductModel;
}

export const PriceBreakdown: FC<PriceBreakdownProps> = ({ product }: PriceBreakdownProps) => {
  if (product.price === 0) {
    return <span className="text-success">Free!</span>;
  }

  return (
    <span>
      <Currency value={product.price} />
    </span>
  );
};
