import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Table } from 'reactstrap';
import * as OrderSummary from '../../../../../../shared/orders/model';
import { Currency } from '../../../../../components/Currency';
import { checkPermission, useUser } from '../../../../../utils';

interface OrderVouchersProps {
  readonly order: OrderSummary.Order;
  readonly openAddVoucherModal: () => void;
  readonly openDeleteVoucherModal: (voucherId: number) => void;
}

export const OrderVouchers: FC<OrderVouchersProps> = (props) => {
  const { order, openAddVoucherModal, openDeleteVoucherModal } = props;
  const { vouchers } = order;
  const user = useUser();
  const canEdit = order.status === 'unpaid' && checkPermission(user!, 'comp:create');

  return (
    <Col lg={8} xs={12}>
      <Card>
        <CardHeader>
          Vouchers
          {canEdit && (
            <Button
              color="primary"
              id="addVoucher"
              onClick={openAddVoucherModal}
              style={{ float: 'right', padding: '0px 5px' }}
            >
              +
            </Button>
          )}
        </CardHeader>
        <CardBody>
          {vouchers.length === 0 && <>There are no vouchers for this order.</>}
          {vouchers.length > 0 && (
            <Table>
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Display Name</th>
                  <th>Amount</th>
                  {canEdit && <th />}
                </tr>
              </thead>
              <tbody>
                {vouchers.map((voucher) => (
                  <tr id={`voucher-${voucher.id!}`} key={voucher.code}>
                    <td>
                      {voucher.id ? (
                        <Link to={`/housekeeping/vouchers/${voucher.id}`}>{voucher.code}</Link>
                      ) : (
                        <>{voucher.code}</>
                      )}
                    </td>
                    <td>{voucher.displayName}</td>
                    <td>
                      <Currency value={voucher.amount} />
                    </td>
                    {voucher.id && canEdit && (
                      <td>
                        <Button
                          color="danger"
                          id="deleteVoucher"
                          onClick={() => {
                            openDeleteVoucherModal(voucher.id!);
                          }}
                          style={{ float: 'right', padding: '0px 5px' }}
                        >
                          -
                        </Button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};
