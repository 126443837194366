import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import * as OrderSummary from '../../shared/orders/model';
import { ProductCategoryModel } from '../../shared/orders/product';
import { OrderItemProduct, renderOrderSummary } from '../containers';
import { OrderBadges } from '../containers/housekeeping/attendee/utils';
import { distinct, useConfig, useConvention } from '../utils';
import { Currency } from './Currency';
import { MaterialIconText } from './MaterialIconText';
import { PermissionBoundary } from './PermissionBoundary';
import { MaterialIcon } from '.';

interface StaffOrderOptionsProps {
  readonly order: OrderSummary.Order;
  readonly id: string;
}

const StaffOrderOptions: FC<StaffOrderOptionsProps> = ({ order, id }) => {
  return (
    <PermissionBoundary inline requiredPermissions={['order:view']}>
      <Link id={`${id}_details`} to={`/housekeeping/orders/${order.id}`}>
        <Button color="info" style={{ padding: '0px 5px' }}>
          Show details
        </Button>
      </Link>
    </PermissionBoundary>
  );
};

interface CustomerOrderOptionsProps {
  readonly id: string;
  readonly order: OrderSummary.Order;
  readonly onSelect: (option: OrderItemSelectOption) => void;
  readonly hasRefunds?: boolean;
}

const CustomerOrderOptions: FC<CustomerOrderOptionsProps> = ({
  order,
  id,
  hasRefunds,
  onSelect,
}) => {
  const convention = useConvention();

  if (order.status !== 'paid') {
    return null;
  }

  const items: React.ReactElement[] = [];

  if (hasRefunds) {
    items.push(
      <DropdownItem
        id={`${id}RefundHistory`}
        key="refundHistory"
        onClick={() => {
          onSelect('viewRefundHistory');
        }}
      >
        View Refund History
      </DropdownItem>,
    );
  }

  if (order.breakdown.paid > 0) {
    items.push(
      <DropdownItem
        className="text-danger"
        id={`${id}Refund`}
        key="refund"
        onClick={() => {
          if (convention.disableRefunds !== null) {
            toast.error(`Refunds are disabled: ${convention.disableRefunds}`);
            return;
          }

          onSelect('requestRefund');
        }}
        style={{
          cursor: convention.disableRefunds === null ? undefined : 'not-allowed',
          textDecoration: convention.disableRefunds === null ? undefined : 'line-through',
        }}
      >
        Request Refund
      </DropdownItem>,
    );
  }

  if (items.length === 0) {
    return null;
  }

  return (
    <UncontrolledDropdown className="mw-100">
      <DropdownToggle id={`${id}MoreOptions`} tag="a">
        <MaterialIcon className="pointer" name="more_horiz" />
      </DropdownToggle>
      <DropdownMenu right>{items}</DropdownMenu>
    </UncontrolledDropdown>
  );
};

const RefundState: FC<{
  readonly refunds?: OrderSummary.Refund[];
  readonly isCustomer?: boolean;
  readonly id: string;
}> = ({ refunds = [], isCustomer = false, id }) => {
  if (refunds.some(({ status }) => status === 'pending')) {
    return (
      <Alert color="warning" id={`${id}RefundPending`}>
        <MaterialIconText name="hourglass_empty">
          {isCustomer ? 'You have' : 'There is'} a pending refund request for this order.
        </MaterialIconText>
      </Alert>
    );
  }

  if (refunds.some(({ status }) => status === 'denied')) {
    return (
      <Alert color="danger" id={`${id}RefundDenied`}>
        <MaterialIconText name="clear">
          {isCustomer ? 'This' : 'Your'} refund request has been denied
          {isCustomer && ' by the event'}.
        </MaterialIconText>
      </Alert>
    );
  }

  const approvedRefund = refunds
    .sort((a, b) => b.id - a.id)
    .find(({ status }) => status === 'approved');

  if (approvedRefund) {
    return (
      <Alert color="success" id={`${id}RefundApproved`}>
        <MaterialIconText name="done">
          {isCustomer
            ? 'Your refund request has been approved by the event. You'
            : 'This refund request has been approved. The customer'}{' '}
          should receive a refund of
          <strong>
            {' '}
            <Currency value={approvedRefund.amount} />
          </strong>
          . Processing may take a few days.
        </MaterialIconText>
      </Alert>
    );
  }

  return null;
};

const PaymentFlags: FC<{
  readonly payments?: OrderSummary.Payment[];
  readonly isCustomer?: boolean;
  readonly id: string;
}> = ({ payments = [], isCustomer = false, id }) => {
  if (isCustomer) {
    return null;
  }

  const flags: OrderSummary.FlagReason[] = distinct(
    payments
      .filter(({ status }) => status === 'success')
      .reduce<OrderSummary.FlagReason[]>(
        (allFlags, { flagReasons: newFlags = [] }) => allFlags.concat(newFlags),
        [],
      ),
  );

  if (flags.length === 0) {
    return null;
  }

  return (
    <Alert color="info" id={`${id}TransactionFlagged`}>
      <MaterialIconText name="flag">
        This transaction has been tagged with the following review flags:
        <br />
        <strong>{flags.join(', ')}</strong>
      </MaterialIconText>
    </Alert>
  );
};

export type OrderItemSelectOption = 'requestRefund' | 'viewRefundHistory';

interface OrderItemElementProps extends CustomerOrderOptionsProps {
  readonly categories: ProductCategoryModel[];
  readonly isCustomer?: boolean;
}

export const OrderItemElement: FC<OrderItemElementProps> = ({
  categories,
  order,
  id,
  isCustomer = false,
  hasRefunds,
  onSelect,
}) => {
  const convention = useConvention();
  const config = useConfig();
  const orderConvention = config.conventions.find((t) => t.id === order.conventionId);

  if (!orderConvention) {
    return null;
  }

  return (
    <Col className="resultTable order-item-element" id={id} xs={12}>
      <Card className="table">
        <CardHeader>
          <div className="float-left">
            Order #{order.id}
            {order.conventionId !== convention.id && <>&nbsp;({orderConvention.shortName})</>}
            &mdash;
            <Currency value={order.breakdown.total} />
          </div>
          <div className="float-left" style={{ paddingLeft: '5px' }}>
            <OrderBadges order={order} />
          </div>
          <div className="float-right">
            {isCustomer ? (
              <CustomerOrderOptions
                hasRefunds={hasRefunds}
                id={id}
                onSelect={onSelect}
                order={order}
              />
            ) : (
              <StaffOrderOptions id={id} order={order} />
            )}
          </div>
          {order.paidAt && (
            <>
              <br />
              <div>
                Order paid: &nbsp;
                <span title={order.paidAt.toLocaleString()}>
                  {order.paidAt.toLocaleDateString()}
                </span>
              </div>
            </>
          )}
        </CardHeader>
        <PaymentFlags id={id} isCustomer={isCustomer} payments={order.payments} />
        <RefundState id={id} isCustomer={isCustomer} refunds={order.refunds} />
        <CardBody>
          {order.orderItems.map((item) => (
            <OrderItemProduct item={item} key={item.id} order={order} readOnly />
          ))}
          {order.orderItems.length > 0 && <hr />}
          <br />
          <h4>Order Summary</h4>
          {renderOrderSummary({
            categories,
            isCustomer,
            order,
            readOnly: order.status === 'paid',
          })}
        </CardBody>
        {isCustomer && order.orderItems.some((o) => !!o.ticket) && (
          <CardFooter className="d-inline-flex align-items-center order-ticket-notice">
            <div className="flex-fill">
              <MaterialIconText name="confirmation_number" type="primary">
                You have one or more tickets for this order.
              </MaterialIconText>
            </div>
            <Link to={`/order/${order.id}/tickets`}>
              <Button color="primary" outline type="button">
                Retrieve Ticket(s)
              </Button>
            </Link>
          </CardFooter>
        )}
      </Card>
    </Col>
  );
};
