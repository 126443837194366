import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import { Order, OrderItem } from '../../shared/orders/model';
import { refundRequestReasons } from '../models';
import { displayName, generateRange, sumBy } from '../utils';
import { Currency } from './Currency';

interface RefundRequestModalProps {
  readonly order: Order;
  onSuccess(): void;
  onCancel(): void;
}

type RefundRequestFormData = Record<string, number>;

interface RefundRequestPostData {
  refundItems: Record<number, number>;
  refundReason: string;
  refundAddInfo?: string;
}

export const RefundRequestModal: FC<RefundRequestModalProps> = ({ order, onCancel, onSuccess }) => {
  function onRefundRequest(): void {
    toast.success('The refund request has been submitted and is pending approval.');
    onSuccess();
  }

  function calculateRemainingQuantity(item: OrderItem): number {
    return (
      item.quantity -
      sumBy(order.refunds || [], ({ items, status }) => {
        const refundItem = items.find(({ itemId }) => itemId === item.id);
        return !refundItem || status === 'denied' ? 0 : refundItem.quantity;
      })
    );
  }

  const totalRemainingQuantity = sumBy(order.orderItems ?? [], (item) =>
    calculateRemainingQuantity(item),
  );

  if (totalRemainingQuantity === 0) {
    return (
      <Modal className="modal-large" id="refundModal" isOpen>
        <ModalHeader>Request Refund: Order #{order.id}</ModalHeader>
        <ModalBody>
          <p>There are no further items eligible to refund on this order.</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  return (
    <Modal className="modal-large" id="refundModal" isOpen>
      <JSONForm<{}, RefundRequestFormData & RefundRequestPostData>
        method="post"
        onSuccess={onRefundRequest}
        path={`/api/orders/${order.id}/refund/request`}
      >
        <ModalHeader>Request Refund: Order #{order.id}</ModalHeader>
        <ModalBody>
          <p>
            The refund request will be submitted to the event and subject to approval according to
            the event's refund policies.
          </p>
          <Table striped>
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Unit Price</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {(order.orderItems || []).map((item) => {
                const key = `orderItem${item.id}`;
                const remainingQuantity = calculateRemainingQuantity(item);

                if (remainingQuantity === 0) {
                  return null;
                }

                // Free addons does not deserve a refund
                if (item.parentOrderItemId) {
                  return null;
                }

                return (
                  <tr key={key}>
                    <td>{displayName(item.product)}</td>
                    <td>
                      <Currency value={item.price} />
                    </td>
                    <td>
                      <Input
                        disabled={remainingQuantity === 0}
                        id={`${key}Quantity`}
                        name={`refundItems[${item.id}]`}
                        type="select"
                      >
                        {generateRange(0, remainingQuantity).map((idx) => (
                          <option key={idx}>{idx}</option>
                        ))}
                      </Input>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <br />
          <FormGroup row>
            <Label for="refundReason" lg={5} sm={12}>
              Reason for Refund
            </Label>
            <Col lg={7} sm={12}>
              <Input id="refundReason" name="refundReason" type="select">
                {refundRequestReasons.map(({ key, description }) => (
                  <option key={key} value={key}>
                    {description}
                  </option>
                ))}
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="refundAddInfo" lg={5} sm={12}>
              Additional Info
            </Label>
            <Col lg={7} sm={12}>
              <Input id="refundAddInfo" name="refundAddInfo" type="textarea" />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel}>
            Cancel
          </Button>{' '}
          <Button color="primary" id="requestRefund" type="submit">
            Request Refund
          </Button>
        </ModalFooter>
      </JSONForm>
    </Modal>
  );
};
