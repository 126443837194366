import React, { FC } from 'react';
import { toast } from 'react-toastify';
import { Badge, ButtonGroup, CardBody, CardFooter, CardHeader } from 'reactstrap';
import {
  ActionButton,
  Filter,
  FilterTable,
  PageHeader,
  UserStateComponent,
} from '../../../components';
import { Currency } from '../../../components/Currency';
import { DateTime } from '../../../components/Date';
import { Fee } from '../../../models';
import { Fetcher, NameProps, renderName, useFetcher } from '../../../utils';
import { captureError } from '../../../utils/errorHandling';
import { CreateFee } from './createFee';

const feeManagementFilters: Filter<Fee>[] = [
  {
    displayName: 'Status',
    name: 'status',
    options: [
      {
        filter: (fee) => !fee.deletedAt && fee.order!.status === 'unpaid',
        name: 'Unpaid',
        value: 'unpaid',
      },
      {
        filter: (fee) => !fee.deletedAt && fee.order!.status === 'paid',
        name: 'Paid',
        value: 'paid',
      },
      {
        filter: (fee) => !!fee.deletedAt,
        name: 'Deleted / Archived',
        value: 'deleted',
      },
    ],
  },
];

export const FeeManagement: FC = () => {
  const fetcher = useFetcher(async () => {
    return await api.getAllFees();
  });

  if (!fetcher.complete) {
    return <Fetcher result={fetcher} />;
  }

  return (
    <UserStateComponent>
      <PageHeader>Fee Management</PageHeader>
      <FilterTable<Fee>
        data={fetcher.data ?? []}
        filters={feeManagementFilters}
        infoRenderer={(fee) => <InfoSection fee={fee} refresh={fetcher.refresh} />}
        renderer={(fee) => <FeeItem fee={fee} />}
      />
    </UserStateComponent>
  );
};

const InfoSection: FC<{ readonly fee?: Fee; readonly refresh: () => void }> = ({
  fee,
  refresh,
}) => {
  if (!fee) {
    return (
      <CardBody>
        Select a refund request from the left side to manage it, or use the form to create a new
        one.
        <hr />
        <CreateFee
          onSuccess={() => {
            toast.success('New fee has been created');
            refresh();
          }}
        />
      </CardBody>
    );
  }

  return (
    <>
      <CardHeader>
        Fee #{fee.id} &mdash; <Currency value={fee.amount} /> &nbsp;
        <FeeBadge fee={fee} />
      </CardHeader>
      <CardBody>
        <h6>Created</h6>
        <p>
          <DateTime value={fee.createdAt} />
        </p>

        <h6>Issuer</h6>
        <p>
          {fee.issuer!.username} (ID {fee.issuerId})
        </p>

        <h6>Reason</h6>
        <p>{fee.reason}</p>
      </CardBody>
      {fee.order!.status === 'unpaid' && !fee.deletedAt && (
        <CardFooter>
          <ButtonGroup>
            <ActionButton
              action={`/api/fees/${fee.id}`}
              color="danger"
              id="deleteFee"
              method="delete"
              onFailure={captureError}
              onSuccess={() => {
                toast.success('Fee has been deleted');
                refresh();
              }}
            >
              Delete Fee
            </ActionButton>
          </ButtonGroup>
        </CardFooter>
      )}
    </>
  );
};

const FeeItem: FC<{ readonly fee: Fee }> = ({ fee }) => {
  return (
    <div className="table-item">
      <p>
        Fee #{fee.id} &mdash; <Currency value={fee.amount} /> &nbsp;
        <FeeBadge fee={fee} />
      </p>
      <p>{renderName(fee.user! as NameProps)}</p>
    </div>
  );
};

const FeeBadge: FC<{ readonly fee: Fee }> = ({ fee }) => {
  if (fee.deletedAt) {
    return <Badge color="secondary">Deleted / Archived</Badge>;
  }

  if (fee.order!.status === 'paid') {
    return <Badge color="success">Paid</Badge>;
  }

  return <Badge color="danger">Not Yet Paid</Badge>;
};
