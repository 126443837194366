import React, { FC } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { OrderItemProduct } from '../../../..';
import { Order, OrderItem, Refund, RefundItem } from '../../../../../../shared/orders/model';
import { IconColor } from '../../../../../components';
import { Currency } from '../../../../../components/Currency';
import { MaterialIconText } from '../../../../../components/MaterialIconText';
import { displayName, sumBy } from '../../../../../utils';

interface OrderInfoItemGroupProps {
  readonly title: string;
  readonly order: Order;
  readonly orderItems: OrderItem[];
  readonly icon: string;
  readonly iconColor?: IconColor;
  readonly addButton?: () => void;
  readonly onUpdate?: () => void;
}

export const OrderInfoItemGroup: FC<OrderInfoItemGroupProps> = (props) => {
  const { title, orderItems, icon, iconColor, order, addButton, onUpdate } = props;

  // Only keep refunds where there is an item
  const refunds = order.refunds
    .map((refund) => {
      return {
        ...refund,
        items: refund.items.filter((i) => orderItems.some((u) => u.id === i.itemId)),
      };
    })
    .filter((refund) => refund.items.length > 0);

  const totalCount = orderItems.length + sumBy(refunds, (t) => t.items.length);

  return (
    <Card className="margin-bottom-10">
      <CardHeader>
        {addButton && (
          <Button
            color="primary"
            id="addProduct"
            onClick={addButton}
            style={{ float: 'right', padding: '0px 5px' }}
          >
            +
          </Button>
        )}
        <MaterialIconText name={icon} type={iconColor}>
          {title} &mdash; {totalCount} Item(s)
        </MaterialIconText>
      </CardHeader>
      <CardBody>
        {orderItems.map((item) => (
          <OrderItemProduct
            adminControls
            cashierUI
            item={item}
            key={item.id}
            onUpdate={onUpdate}
            order={order}
            readOnly={!addButton}
          />
        ))}
        {refunds.map((refund) => {
          return (
            <div className="order-line" key={refund.id}>
              <div className="order-item">
                <div className="order-item-description">
                  Refund #{refund.id} - Status: {refund.status}
                </div>
                <div className="order-item-price">
                  -<Currency value={refund.amount} />
                </div>
              </div>
              {refund.items.map((refundItem) => {
                return (
                  <RefundItemDisplay
                    key={refundItem.itemId}
                    orderItem={orderItems.find((t) => t.id === refundItem.itemId)!}
                    refund={refund}
                    refundItem={refundItem}
                  />
                );
              })}
            </div>
          );
        })}
      </CardBody>
    </Card>
  );
};

interface RefundItemProps {
  readonly refund: Refund;
  readonly refundItem: RefundItem;
  readonly orderItem: OrderItem;
}

const RefundItemDisplay: FC<RefundItemProps> = (props) => {
  const { refund, refundItem, orderItem } = props;
  const { product } = orderItem;

  return (
    <div id={`refundItem-${refundItem.itemId}`}>
      <div className="order-item">
        <div className="order-item-description">
          <MaterialIconText medium name="delete_forever" type="danger">
            {refundItem.quantity > 1 && `-${refundItem.quantity} x `}
            {displayName(product)} &mdash; {product.category.name}
            <br />
            <small>
              {refund.status} - {refund.requestReason} - {refund.requestAddInfo}
            </small>
          </MaterialIconText>
        </div>
      </div>
    </div>
  );
};
