import React, { FC } from 'react';
import * as OrderSummary from '../../shared/orders/model';
import { classNames } from '../utils';
import { Currency } from './Currency';
import { PaymentInfo } from './PaymentInfo';

interface OrderPaymentListProps {
  readonly order: OrderSummary.Order;
  readonly isPaid: boolean;
  readonly isCustomer: boolean;
}

export const OrderPaymentList: FC<OrderPaymentListProps> = ({ order, isPaid, isCustomer }) => {
  const { payments } = order;

  const paymentsSegment = payments
    .filter(({ status }) => status === 'success' || status === 'refunded')
    .map(({ id, amount, status, gateway, cardBrand, cardLast4, reference }) => {
      const isSuccess = status === 'success';

      return (
        <div
          className={classNames({
            'order-summary-item': !isPaid,
            'order-total': isPaid,
            'text-info': status === 'refunded',
            'text-success': isPaid || status === 'success',
          })}
          key={id}
        >
          <div
            className={classNames({
              'order-summary-description': !isPaid,
              'order-total-description': isPaid,
            })}
          >
            {isSuccess ? (
              <PaymentInfo cardBrand={cardBrand} cardLast4={cardLast4!} gateway={gateway} />
            ) : (
              'Refund by Event'
            )}
            &nbsp;
            <small>{!isCustomer && `(Gateway: ${gateway}, Ref: ${reference!})`}</small>
          </div>
          <div className="order-total-price">
            <Currency value={isSuccess ? amount : -amount} />
          </div>
        </div>
      );
    });

  const successfulPayments = payments
    .filter(({ status }) => status === 'success')
    .map(({ amount }) => amount)
    .reduce((a, b) => a + b, 0);

  const hasPartiallyPaid = order.status === 'unpaid' && successfulPayments > 0;
  const balance = order.breakdown.total - successfulPayments;

  return (
    <>
      <hr />
      {paymentsSegment}
      {!isPaid && (
        <div className="order-total">
          <div className="order-total-description">
            {hasPartiallyPaid ? 'Remaining Balance' : 'Order Total'}
          </div>
          <div className="order-total-price">
            <Currency value={balance} />
          </div>
        </div>
      )}
    </>
  );
};
