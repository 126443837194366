import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  PopoverBody,
  UncontrolledDropdown,
  UncontrolledPopover,
} from 'reactstrap';
import * as OrderSummary from '../../../shared/orders/model';
import { ProductCategoryModel, ProductModel } from '../../../shared/orders/product';
import {
  AgeRestrictedProductBadge,
  MaterialIcon,
  OrderPaymentList,
  PaymentState,
} from '../../components';
import { Currency } from '../../components/Currency';
import { OptionViewer } from '../../components/OptionViewer';
import {
  classNames,
  displayName,
  handleUnknownOptionError,
  isLogicError,
  sumBy,
  useNumericInputState,
} from '../../utils';
import { useDebounceValue } from '../../utils/debounce';
import { captureError, LogicError } from '../../utils/errorHandling';

interface OrderItemProductProps {
  readonly item: OrderSummary.OrderItem;
  readonly order: OrderSummary.Order;
  readonly readOnly?: boolean;
  readonly cashierUI?: boolean;
  readonly adminControls?: boolean;
  onUpdate?(): void;
}

export const OrderItemProduct: FC<OrderItemProductProps> = ({
  cashierUI,
  order,
  item,
  readOnly,
  adminControls,
  onUpdate,
}) => {
  const [quantity, setQuantityEv, setQuantity] = useNumericInputState(item.quantity);
  const [lastSafeQuantity, setLastSafeQuantity] = useState(item.quantity);
  const debouncedQuantity = useDebounceValue<number>(quantity, 350);

  const { product } = item;

  const deleteItem = async () => {
    if (readOnly) {
      return;
    }

    await api.removeProductFromCart(order.id, { orderItemId: item.id });
    if (onUpdate) {
      onUpdate();
    }
  };

  useEffect(() => {
    const updateItemQuantity = async () => {
      if (readOnly || !item.product.category.isStore || debouncedQuantity === lastSafeQuantity) {
        return;
      }

      try {
        await api.updateProductQuantity(order.id, item.id, quantity);
        setLastSafeQuantity(quantity);
      } catch (error) {
        if (isLogicError(error, LogicError.UnknownProductOption)) {
          handleUnknownOptionError(error, item.options);
        } else {
          setQuantity(lastSafeQuantity);
          captureError(error as Error);
        }
      }

      if (onUpdate) {
        onUpdate();
      }
    };

    void updateItemQuantity();
  }, [debouncedQuantity, lastSafeQuantity, item]);

  const isProductFree = item.price === 0;

  return (
    <div className="order-line" id={`orderItem-${item.id}`}>
      <div className="order-item">
        <div className="order-item-description">
          {readOnly && item.quantity > 1 && `${item.quantity} x `}
          {displayName(product)} &mdash; {product.category.name}
          {order.status === 'paid' && <GrantStatus orderItem={item} />}
          <br />
          <small>Product ID - {product.id}</small>
          {!!item.referenceId && <small>, Reference ID - {item.referenceId}</small>}
          {cashierUI && (product.maxAge || product.minAge) && (
            <div className="badge-list compact">
              <AgeRestrictedProductBadge
                audience="Customer"
                idPrefix={`orderItem${item.id}`}
                maxAge={product.maxAge ?? undefined}
                minAge={product.minAge ?? undefined}
                title={"Verify Customer's Age"}
              />
            </div>
          )}
          {item.options.length > 0 && <OptionViewer optionOrderItems={item.options} />}
          {item.parentOrderItemId && (
            <div className="order-item-addons">
              Free addon from{' '}
              {displayName(
                order.orderItems.find((t) => t.id === item.parentOrderItemId)?.product ?? {},
              )}
            </div>
          )}
        </div>
        {!readOnly && !item.parentOrderItemId && (
          <div className="order-item-price">
            {canEditProductQuantity(product) && (
              <Input
                id="quantity"
                min="1"
                name="quantity"
                onChange={setQuantityEv}
                type="number"
                value={quantity}
              />
            )}
            {(cashierUI || product.category.isStore) && !item.parentOrderItemId && (
              <div className="order-item-delete" onClick={deleteItem}>
                Delete
              </div>
            )}
          </div>
        )}

        <div className={classNames({ 'text-success': isProductFree }, 'order-item-price')}>
          {adminControls && <OrderItemAdminControls item={item} reload={onUpdate} />}
          {isProductFree ? 'Free' : <Currency value={item.price} />}
        </div>
      </div>
      {item.surcharges.map((surcharge) => (
        <OrderItemProductSurcharge item={item} key={surcharge.id} surcharge={surcharge} />
      ))}
      {item.surcharges.length > 0 && (
        <div className="order-surcharge-total" id={`orderItem-${item.id}-surcharge-total`}>
          <div className="order-item-description">
            <small>Total w/ Surcharges</small>
          </div>
          <div className="order-item-price">
            <strong>
              <Currency value={item.price + sumBy(item.surcharges, (s) => s.totalAmount)} />
            </strong>
          </div>
        </div>
      )}
    </div>
  );
};

const OrderItemProductSurcharge: FC<{
  readonly item: OrderSummary.OrderItem;
  readonly surcharge: OrderSummary.Surcharge;
}> = ({ item, surcharge }) => {
  const { amount, amountType, type } = surcharge;
  const surchargeAmountText = amountType === 'fixed' ? null : `@ ${(amount / 100).toFixed(2)}%`;

  return (
    <div className="order-surcharge-item" id={`orderItem-${item.id}-surcharge-${surcharge.id}`}>
      <div className="order-item-description">
        <small>
          {type === 'tax' ? 'Tax' : 'Fee / Surcharge'} &mdash;{' '}
          {surcharge.displayName ?? surcharge.name} {surchargeAmountText}
        </small>
      </div>
      <div className="order-item-price">
        {surcharge.totalAmount > 0 ? '+' : '-'}{' '}
        <Currency
          value={surcharge.totalAmount > 0 ? surcharge.totalAmount : -surcharge.totalAmount}
        />
      </div>
    </div>
  );
};

const OrderItemAdminControls: FC<{ readonly item: OrderSummary.OrderItem; reload?(): void }> = ({
  item,
  reload,
}) => {
  const setActiveRegistration = useCallback(async () => {
    try {
      await api.setActiveRegistrationPayment(item.id);
      reload?.();
    } catch (error) {
      captureError(error as Error);
    }
  }, [item.id]);

  if (!item.product.category.isRegistration) {
    return null;
  }

  if (item.referenceId) {
    return null;
  }

  return (
    <div>
      <UncontrolledDropdown>
        <DropdownToggle id={`orderItemDropdown${item.id}`} tag="a">
          <MaterialIcon className="action-more-options clickable" name="more_horiz" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem className="orderItem-set-active" onClick={setActiveRegistration}>
            Set as active registration
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

export const GrantStatus: FC<{ readonly orderItem: OrderSummary.OrderItem }> = ({ orderItem }) => {
  const id = `orderItemGrant-${orderItem.id}`;
  return (
    <span className="grant-status">
      <span className="inline-icon" id={id}>
        <MaterialIcon
          className={orderItem.referenceId ? 'yes' : 'no'}
          name={orderItem.referenceId ? 'done' : 'close'}
          type={orderItem.referenceId ? 'success' : 'danger'}
        />
      </span>
      <UncontrolledPopover placement="bottom" target={id} trigger="hover">
        <PopoverBody>
          {orderItem.referenceId ? (
            <p>This user has an active entitlement for this order item.</p>
          ) : (
            <p>This order item is not active for the user.</p>
          )}
        </PopoverBody>
      </UncontrolledPopover>
    </span>
  );
};

interface ProductCanEditProps extends Pick<ProductModel, 'isTicketed' | 'maxGrants'> {
  category: {
    isStore: boolean;
  };
}

export function canEditProductQuantity(product: ProductCanEditProps): boolean {
  return (
    product.category.isStore && !product.isTicketed && (!product.maxGrants || product.maxGrants > 1)
  );
}

interface RenderOrderSummaryProps {
  order: OrderSummary.Order;
  categories: ProductCategoryModel[];
  paymentState?: PaymentState;
  isCustomer?: boolean;
  readOnly?: boolean;
}

export function renderOrderSummary({
  order,
  categories,
  isCustomer,
  paymentState,
  readOnly,
}: RenderOrderSummaryProps): JSX.Element {
  const isPaid = readOnly ?? paymentState === PaymentState.Success;
  const taxSurcharges = sumBy(order.orderItems, (i) => sumBy(i.surcharges, (s) => s.totalAmount));
  return (
    <>
      {categories.map((category) => renderCategoryTotal(order.orderItems, category))}
      {order.fees && order.fees.length > 0 && (
        <div className="order-summary-item">
          <div className="order-summary-description">Fees, Charges, and Fines</div>
          <div className="order-summary-price">
            <Currency value={sumBy(order.fees, (fee) => fee.amount)} />
          </div>
        </div>
      )}
      <hr />
      <div className="order-summary-item">
        <div className="order-summary-description">Taxes &amp; Surcharges</div>
        <div className="order-summary-price">
          <Currency value={taxSurcharges} />
        </div>
      </div>
      {order.vouchers.map((v) => (
        <VoucherInfo key={v.code} voucher={v} />
      ))}
      <OrderPaymentList isCustomer={isCustomer ?? false} isPaid={isPaid} order={order} />
      {order.breakdown.refunds > 0 && isPaid && (
        <div className="text-success order-total">
          <div className="order-total-description">Refund Total</div>
          <div className="order-total-price">
            - <Currency value={order.breakdown.refunds} />
          </div>
        </div>
      )}
    </>
  );
}

interface VoucherInfoProps {
  readonly voucher: OrderSummary.Voucher;
}

const VoucherInfo: FC<VoucherInfoProps> = ({
  voucher: { amount, code, id, displayName: dName },
}) => {
  return (
    <div className="order-discount-item" id={`vouchers${id ?? '-new'}`} key={code}>
      <div className="order-discount-description text-success">
        Discount: {dName ? `${dName} (${code})` : code}
      </div>
      <div className="order-discount-price text-success">
        - <Currency value={amount} />
      </div>
    </div>
  );
};

export function renderCategoryTotal(
  items: OrderSummary.OrderItem[],
  category: ProductCategoryModel,
): JSX.Element | null {
  const applicableItems = items.filter(({ product }) => product.category.id === category.id);

  if (applicableItems.length === 0) {
    return null;
  }

  const total = sumBy(applicableItems, (item) => item.price * item.quantity);

  return (
    <div className="order-summary-item" key={category.id}>
      <div className="order-summary-description">{category.name}</div>
      <div className="order-summary-price">
        <Currency value={total} />
      </div>
    </div>
  );
}
