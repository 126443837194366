import React, { FC } from 'react';
import { Payment } from '../../../../../../shared/orders/model';
import { Currency } from '../../../../../components/Currency';
import { DateTime } from '../../../../../components/Date';
import { PaymentStatusBadge } from '../../../../../models';

interface OrderPaymentTableItemProps {
  readonly payment: Payment;
}

export const OrderPaymentTableItem: FC<OrderPaymentTableItemProps> = ({ payment }) => {
  return (
    <tr>
      <td>
        <Currency value={payment.amount} />
      </td>
      <td>
        <PaymentStatusBadge payment={payment} />
      </td>
      <td>{payment.gateway}</td>
      <td>{payment.reference}</td>
      <td>
        <DateTime value={payment.createdAt} />
      </td>
    </tr>
  );
};
